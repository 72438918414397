import React from 'react';
import NavBarMenu from './navbar';

const Header = () => (
  <>
      {/* <nav class="left">
      
      */}
    <NavBarMenu />
    </>
);

export default Header;
