import React from 'react';
import Layout   from '../components/common/layout';
import Footer   from '../components/common/Footer';
import img3 from "../images/help-img-2.svg";
import img1 from "../images/help-img-3.svg";
import img4 from "../images/help-img-4.svg";
import Header from '../components/common/Header';
import { Helmet } from "react-helmet";
const COVID = () => {
    return(
      <Layout>
      <Header />
        <Helmet>            
            <meta charset="utf-8" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <title>LaundryToGo - Covid Info</title> 
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=yes" />
            <meta name="author" content="LaundryToGo - We Make Laundry Easy" />
            <meta name="description" content="LaundryToGo Service during Covid Pandemic." />
        </Helmet>
      <div id="wrapper" style={{color: "#000"}}>
        <div className="container covidFirstContainer">
            <div className="row">
                <div className="col-md-6">
                    <h3 className="covid-heading">Extra measures that we take in COVID-19 times</h3>
                    <p>COVID-19 Safe with No-Contact Pick Up and Delivery. Elevated temperatures in our washes inactivate microbes and
                        viruses on fabrics and garments</p>
                </div>
                <div className="col-md-6">
                    <h3 className="covid-heading">We make sure that our employees are tested on a regular basis.</h3>
                </div>
            </div>
        </div>
        <div className="container covidSecondContainer" style={{padding: "80px 20px"}}>
            <div className="row">
                <div className="col-md-6">
                    <h3 className="covid-heading">Our employees make sure they sanitize their hands before and after every pick up or delivery.</h3>
                </div>
                <div className="col-md-6">
                <img src={img3} style={{maxHeight: "400px", width: "100%"}} />
                </div>
            </div>
        </div>
        <div className="container covidSecondContainer" style={{padding: "80px 20px"}}>
            <div className="row">
                <div className="col-md-7">
                    <h3 className="covid-heading">Supporting public authorities.</h3>
                </div>
                <div className="col-md-5">
                  <p>COVID-19 Safe with No-Contact Pick Up and Delivery. Elevated temperatures in our washes inactivate microbes and
                        viruses on fabrics and garments. COVID-19 Safe with No-Contact Pick Up and Delivery.</p>
                </div>
            </div>
        </div>
        <div className="container covidLastContainer" style={{padding: "80px 20px"}}>
            <div className="row">
                <div className="col-md-12">
                  <img src={img4} style={{maxHeight: "400px", width: "100%"}} />
                </div>
            </div>
        </div>
        
        </div>
        <Footer bg={"white"} />
  </Layout>
    )
}

export default COVID;